@font-face {
  font-family: "NeueHaasDisplay-Light";
  src: local("NeueHaasDisplay-Light"),
    url("./assets/fonts/NeueHaasDisplay-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "NeueHaasDisplay-Roman";
  src: local("NeueHaasDisplay-Roman"),
    url("./assets/fonts/NeueHaasDisplay-Roman.ttf") format("truetype");
  font-display: swap;
}

* {
  font-family: "NeueHaasDisplay-Roman";
  box-sizing: border-box;
}

body {
  margin: 0;
}
